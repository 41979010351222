'use client'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Image from 'next/image'

const Section9 = () => {
  return (
    <div className="py-20 w-[90%] mx-auto">
      <div className="flex items-center justify-center lg:flex-row flex-col">
        <Image src="/img/logos/SOC2.svg" alt="SOC2" width={160} height={160} />
        <div className="flex flex-col lg:ml-12 lg:mt-0 mt-6">
          <h2 className="text-[24px] font-[600] mb-6">
            Loop is now SOC2 compliant
          </h2>
          <p className="text-[#5F5F5F] flex items-center">
            <AdminPanelSettingsIcon className="mr-2" /> Data security is our top
            priority.
          </p>
          <p className="text-[#5F5F5F] flex items-center mt-1">
            <VisibilityIcon className="mr-2" />
            Transparency in data safeguard measures.
          </p>
        </div>
      </div>
    </div>
  )
}
export default Section9
