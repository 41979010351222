/* eslint-disable @next/next/no-img-element */
'use client'
import Image, { StaticImageData } from 'next/image'
import quotes from 'src/assets/double_quote.png'
import Btn from 'src/components/Btn'
import { testimonials } from 'src/utils/data'
import { description } from 'src/utils/text'
// import mac from '../assets/webp/mac.webp'
import { useState } from 'react'
import './style.scss'

interface CardProps {
  img: StaticImageData | string
  words: string
  name: string
  designation: string
  text?: number
  orgLogo?: StaticImageData | string
}
const Card = ({ img, words, name, designation, text, orgLogo }: CardProps) => {
  return (
    <div className="bg-[#F5F2ED] md:min-h-[370px] z-10 rounded-xl 2xl:rounded-2xl">
      <div className="h-full pt-3 pb-6 px-6 md:pt-5 md:pb-6 md:px-7 2xl:pt-12 2xl:pb-10 2xl:px-11 flex md:gap-6 flex-col justify-between">
        <div className="flex flex-col gap-4">
          <Image
            className="w-[63px] h-[67px] object-scale-down"
            src={quotes}
            alt="img"
          />
          <p
            className="text-[12px] md:text-[14px] 2xl:text-[22px] leading-[23px] 2xl:leading-[33px] text-[#413D45]"
            dangerouslySetInnerHTML={{ __html: words }}></p>
        </div>
        <div className="flex gap-[12px] items-start mt-6">
          <Image
            className="w-[40px] h-[40px] object-scale-down rounded-md"
            width={40}
            height={40}
            src={img}
            alt="img"
          />
          {orgLogo && (
            <Image
              className="h-[40px] object-scale-down"
              height={40}
              width={60}
              src={orgLogo}
              alt="img"
            />
          )}
          <div className="flex flex-col max-h-[40px] overflow-visible text-[12px] 2xl:text-[15px] leading-4 2xl:leading-6 ml-4">
            <ul className="list-disc">
              <li>
                <p className="text-[10px] md:text-[12px] text-[#1e1e1e]">
                  <b>{name}</b>
                  <br />
                  {designation}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function Section1() {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseHover = () => {
    setIsHovered(!isHovered)
  }
  return (
    <>
      <div className="pb-0 md:pb-5 relative w-full overflow-hidden bg-white">
        <div className="mx-auto mb-5">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mx-auto w-[85%]">
            <div className="col-span-12 relative z-10 py-8 pt-6 md:pt-16 xl:pt-24 2xl:pt-40 xs:p-2 h-fit bg-white flex items-center justify-center">
              <div className="flex flex-col gap-4 z-10 items-center lg:w-4/5">
                <h2 className="text-[24px] leading-8 md:text-[36px] md:leading-[48px] 2xl:text-[40px] 2xl:leading-[56px] text-dark-blue text-center font-semibold">
                  Fully automated delivery intelligence platform for modern food
                  brands
                </h2>
                <h5 className="mb-4 text-sm leading-5 md:text-[16px] 2xl:text-[24px] md:leading-6 text-center text-dark-blue">
                  {description}
                </h5>
                <Btn />
              </div>
            </div>

            {/* <div className="hidden md:flex md:col-span-8 pl-[4%] justify-end -z-1 w-full h-[700px] 2xl:h-[900px] overflow-hidden">
              <div className="w-full flex container gap-4">
                <div className="flex flex-col">
                  <div
                    className={`flex flex-col pb-4 gap-4 transform -translate-y-[11%] slide1 ${
                      isHovered ? 'animation-stop' : ''
                    }`}>
                    {testimonials
                      .slice(0, testimonials.length / 2)
                      .map((item) => (
                        <Card
                          key={item.name}
                          img={item.img}
                          words={item.words}
                          name={item.name}
                          designation={item.designation}
                          text={1}
                        />
                      ))}
                  </div>
                  <div
                    className={`flex flex-col pb-4 gap-4 transform -translate-y-[11%] slide1 ${
                      isHovered ? 'animation-stop' : ''
                    }`}>
                    {testimonials
                      .slice(0, testimonials.length / 2)
                      .map((item) => (
                        <Card
                          key={item.name}
                          img={item.img}
                          words={item.words}
                          name={item.name}
                          designation={item.designation}
                          text={1}
                        />
                      ))}
                  </div>
                </div>

                <div className="flex flex-col">
                  <div
                    className={`flex flex-col pb-4 gap-4 slide1 ${
                      isHovered ? 'animation-stop' : ''
                    }`}>
                    {testimonials
                      ?.slice(testimonials.length / 2)
                      .map((item, index) => (
                        <Card
                          key={item.name + index}
                          img={item.img}
                          words={item.words}
                          name={item.name}
                          designation={item.designation}
                          text={2}
                        />
                      ))}
                  </div>
                  <div
                    className={`flex flex-col pb-4 gap-4 slide1 ${
                      isHovered ? 'animation-stop' : ''
                    }`}>
                    {testimonials
                      ?.slice(testimonials.length / 2)
                      .map((item, index) => (
                        <Card
                          key={item.name + index}
                          img={item.img}
                          words={item.words}
                          name={item.name}
                          designation={item.designation}
                          text={2}
                        />
                      ))}
                  </div>
                </div>
                <div
                  className="absolute h-[702px] 2xl:h-[902px] w-full"
                  onMouseEnter={handleMouseHover}
                  onMouseLeave={handleMouseHover}
                  style={{
                    background:
                      'linear-gradient(0deg,white 0%,transparent  85%,white 98%)'
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>

        <div
          className="w-fit mx-3 2xl:mx-5 flex overflow-hidden"
          onMouseEnter={handleMouseHover}
          onMouseLeave={handleMouseHover}>
          <div
            className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 2xl:gap-5 slide3 animation-stop ${
              isHovered ? 'animation-stop' : ''
            }`}>
            {testimonials.map((item, index) => (
              <Card
                key={item.name + index}
                img={item.img}
                words={item.words}
                name={item.name}
                designation={item.designation}
                orgLogo={item.orgLogo}
              />
            ))}
          </div>
          {/* <div
            className={`flex gap-3 2xl:gap-5 pr-3 slide3 ${
              isHovered ? 'animation-stop' : ''
            }`}>
            {testimonials.map((item, index) => (
              <Card
                key={item.name + index}
                img={item.img}
                words={item.words}
                name={item.name}
                designation={item.designation}
                orgLogo={item.orgLogo}
              />
            ))}
          </div> */}
        </div>

        {/* <div id="signup">
          <Section6 />
        </div> */}

        {/* <div className="py-[60px] px-0 w-fit bg-white flex relative">
          <div className="whitespace-nowrap flex">
            <div className="whitespace-nowrap flex slide5">
              {logos1.map((item: any, index) => {
                if (item.isShow) {
                  return (
                    <div className="w-[200px] md:w-[300px] flex justify-center">
                      <img
                        className={`min-h-[60px] max-h-[90px] max-w-[200px] md:min-h-[80px] md:max-h-[100px] md:max-w-[300px] md:opacity-60 px-[40px]`}
                        src={`/img/logos/${item.imgName}`}
                        alt={item.alt}
                      />
                    </div>
                  )
                }
              })}
            </div>
            <div className="whitespace-nowrap flex slide5">
              {logos1.map((item: any, index) => {
                if (item.isShow) {
                  return (
                    <div className="w-[200px] md:w-[300px] flex justify-center">
                      <img
                        className={`min-h-[60px] max-h-[90px] max-w-[200px] md:min-h-[80px] md:max-h-[100px] md:max-w-[300px] md:opacity-60 px-[40px]`}
                        src={`/img/logos/${item.imgName}`}
                        alt={item.alt}
                      />
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}
