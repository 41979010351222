import bolt from '../assets/webp/bolt.webp'
import display_settings from '../assets/webp/display_settings.webp'
import image from '../assets/webp/image.webp'
import img20 from '../assets/webp/img20.webp'
import img21 from '../assets/webp/img21.webp'
import img22 from '../assets/webp/img22.webp'
import notifications from '../assets/webp/notifications.webp'
import rebase from '../assets/webp/rebase.webp'
import settings_suggest from '../assets/webp/settings_suggest.webp'

export const description =
  'Loop is your co-pilot to manage your food brand. Empower your team to minimize errors, stay ahead of food trends and boost revenue through data driven smart workflows.'

export const cards = [
  {
    text: 'Easy one-step self-serve onboarding: Connect your 3rd party aggregators',
    icon: img20,
    description: '3rd Party ',
  },
  {
    text: 'Real time alerts on lost sales due to 126 reasons',
    icon: img21,
    description: '70+  reasons',
  },
  {
    text: 'No need to replace your order manager or PoS, simply upgrade your backoffice',
    icon: img22,
    description: 'Upgrade backoffice',
  },
]

export const cards2 = [
  {
    text: 'Streamline your customer success workflows',
    icon: rebase,
    count: '01',
  },
  {
    text: 'Ensure your stores are opt into marketing',
    icon: settings_suggest,
    count: '02',
  },
  {
    text: 'Don’t lose revenue on inactive stores',
    icon: notifications,
    count: '03',
  },
  {
    text: 'React fast to cancellations - they really hurt',
    icon: bolt,
    count: '04',
  },
  { text: 'Test out your images', icon: image, count: '05' },
  {
    text: 'Optimize your menu on auto-pilot',
    icon: display_settings,
    count: '06',
  },
]
