'use client'
import EastIcon from '@mui/icons-material/East'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import BlogCardsSection from 'src/components/Blogs/BlogCardsSection'
import { BlogType, getAllGhostPosts } from 'src/utils/GhostAPI'

const Section8 = () => {
  const [blogs, setBlogs] = useState<BlogType[]>([])
  const [blogsLoading, setBlogsLoading] = useState<boolean>(true)

  useEffect(() => {
    const getPosts = async () => {
      try {
        setBlogsLoading(true)
        let res = await getAllGhostPosts()
        if (res.posts) setBlogs(res.posts)
      } catch (err) {
        console.log(err)
      } finally {
        setBlogsLoading(false)
      }
    }

    getPosts()
  }, [])

  return (
    <div className="md:w-[80%] w-[90%] mx-auto py-10">
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-[600] md:text-[32px] text-[24px]">
          Blogs and Press releases
        </h1>
        <Link
          href="/blog"
          className="bg-[#196E8233] text-[#196E82] ml-4 flex items-center justify-center w-[180px] py-3 rounded-md">
          <span className="mr-2 font-[600]">View all</span>
          <EastIcon />
        </Link>
      </div>

      <div>
        <BlogCardsSection
          loading={blogsLoading}
          blogs={blogs && blogs.slice(0, 3)}
        />
      </div>
    </div>
  )
}
export default Section8
