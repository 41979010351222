'use client'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { StyledBox } from 'src/components/mui/ReusableComponents'
const chargebackImg = require('../assets/webp/chargeback.webp')
const img43Img = require('../assets/webp/img43.webp')
const store_availabilityImg = require('../assets/webp/store_availability.webp')

const Card = ({
  children,
  h1,
  h2,
  h3
}: {
  children: React.ReactNode
  h1?: string
  h2?: string
  h3?: string
}) => {
  return (
    <div
      className="w-full h-3/6 px-2 py-2"
      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
      <div
        className={` mb-[10px] flex gap-2 cursor-pointer items-center justify-start rounded-[16px] px-0 py-0 text-[8px] md:text-[16px] font-bold normal-case leading-loose shadow-none transition-[opacity] duration-300 ease-linear `}>
        {children}
      </div>
      <div className="text-left flex flex-col gap-1">
        <h2 className="text-[6px] md:text-[12px]">{h1}</h2>
        <h2 className="text-[6px] md:text-[12px]">{h2}</h2>
        <h2 className="text-[6px] md:text-[12px]">{h3}</h2>
      </div>
    </div>
  )
}
const Chip = ({
  children,
  style,
  className
}: {
  children: React.ReactNode
  style: any
  className: string
}) => (
  <div
    style={style}
    className={`${className} [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[9px] md:px-[12px] py-0 text-[11px] md:text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200`}>
    {children}
  </div>
)

export default function Section3() {
  const [ref, inView] = useInView({
    threshold: 0.1
  })
  const [animationKey, setAnimationKey] = useState(false)
  const [s, setS] = useState(1)

  useEffect(() => {
    setAnimationKey(inView)
  }, [inView])

  const sm = false
  return (
    <div className="relative text-center p-3 md:p-13 bg-white">
      <div className="container mx-auto">
        <h3 className="text-4xl md:text-6xl font-normal mx-auto my-12 max-w-[1000px] w-full">
          Take action on inaccurate orders and lost sales
        </h3>
        <StyledBox className="pb-0 mb-4 p-4 md:p-12">
          <div className="w-full bg-white text-center pb-4">
            <div className="py-8 md:px-8">
              <h3 className="text-2xl font-semibold mb-4">
                Improve order accuracy by driving a culture of accountability
              </h3>
              <h3
                className="text-sm font-normal mb-8 opacity-50"
                style={{ lineHeight: '27px' }}>
                Improve order accuracy to delight guests <br /> Diagnose poor
                performance and empower your team with feedback
              </h3>
              <div className="flex flex-wrap w-full justify-center mb-4">
                <Chip
                  className="mb-4 md:mb-0"
                  style={{
                    background: '#C9DADE',
                    color: '#196E82',
                    border: '0.714443px solid #6CABBA'
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="m12 17.425l-9-9L4.4 7l7.6 7.6L17.6 9H13V7h8v8h-2v-4.575l-7 7Z"
                    />
                  </svg>
                  Missed orders
                </Chip>
                <Chip
                  className="mb-4 md:mb-0 ml-4"
                  style={{
                    background: '#FFE2D6',
                    color: '#F15412',
                    border: '0.714443px solid #86482D'
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8.7 15.3q.275.275.7.275t.7-.275l1.9-1.9l1.925 1.925q.275.275.675.275t.7-.3q.275-.275.275-.7t-.275-.7L13.4 12l1.925-1.925Q15.6 9.8 15.6 9.4t-.3-.7q-.275-.275-.7-.275t-.7.275L12 10.6l-1.925-1.925Q9.8 8.4 9.4 8.4t-.7.3q-.275.275-.275.7t.275.7l1.9 1.9l-1.925 1.925Q8.4 14.2 8.4 14.6t.3.7ZM4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.588 1.413T20 20H4Zm0-2V6v12Zm0 0h16V6H4v12Z"
                    />
                  </svg>
                  Cancelled orders
                </Chip>
                <Chip
                  className="mb-4 md:mb-0 ml-4"
                  style={{
                    background: '#C9DCFF',
                    color: '#4383FF',
                    border: '0.714443px solid #4383FF'
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.85 1.488-5.263T7.524 3.05q.025.475.1.963t.25 1.137q-1.8 1.1-2.838 2.913T4 12q0 3.35 2.325 5.675T12 20q3.35 0 5.675-2.325T20 12q0-2.125-1.038-3.95T16.1 5.125q.175-.65.25-1.137t.125-.938q2.55 1.275 4.038 3.675T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-4q-2.5 0-4.25-1.75T6 12q0-1.45.638-2.725T8.45 7.15q.125.375.275.863t.4 1.212q-.55.575-.838 1.275T8 12q0 1.65 1.175 2.825T12 16q1.65 0 2.825-1.175T16 12q0-.8-.288-1.5t-.837-1.275q.2-.6.363-1.113t.287-.962Q16.7 8 17.35 9.275T18 12q0 2.5-1.75 4.25T12 18Zm-1-9.5q-.925-2.8-1.213-3.938T9.5 2.5q0-1.05.725-1.775T12 0q1.05 0 1.775.725T14.5 2.5q0 .925-.288 2.063T13 8.5h-2Zm1 5.5q-.825 0-1.413-.588T10 12q0-.825.588-1.413T12 10q.825 0 1.413.588T14 12q0 .825-.588 1.413T12 14Z"
                    />
                  </svg>
                  Inaccurate orders
                </Chip>
              </div>
            </div>
            <div className=" flex flex-col w-full md:w-5/6 mx-auto h-fit">
              <div className="flex items-start">
                <div className="w-1/5 flex flex-col gap-4 justify-between py-0 md:py-10">
                  <Card
                    h1="Missing Item"
                    h2="Customer Cancellations"
                    h3="Ingredient Error">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m12 17.425l-9-9L4.4 7l7.6 7.6L17.6 9H13V7h8v8h-2v-4.575l-7 7Z"
                      />
                    </svg>
                    Missed order
                  </Card>
                </div>
                <div className="w-4/5 flex flex-col gap-2 h-fit p-2 md:p-8">
                  <div
                    ref={ref}
                    className={`section ${
                      animationKey ? 'Line1 opacity-100' : 'opacity-0'
                    }`}>
                    <div className="bar1">
                      <span className="span1 text-xl font-bold text-black ">
                        254
                      </span>
                    </div>
                    <div className="bar2">
                      <span className="span2">24</span>
                    </div>
                    <div className="bar3">
                      <span className="span3">30</span>
                    </div>
                    <div className="bar4">
                      <span className="span4">200</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full"
                style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
              />
              <div className="flex items-center">
                <div className="w-1/5 flex flex-col gap-4 justify-between py-0 md:py-10">
                  <Card
                    h1="Store Closed"
                    h2="Customer Cancellations"
                    h3="Slow Order Confirmation">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M8.7 15.3q.275.275.7.275t.7-.275l1.9-1.9l1.925 1.925q.275.275.675.275t.7-.3q.275-.275.275-.7t-.275-.7L13.4 12l1.925-1.925Q15.6 9.8 15.6 9.4t-.3-.7q-.275-.275-.7-.275t-.7.275L12 10.6l-1.925-1.925Q9.8 8.4 9.4 8.4t-.7.3q-.275.275-.275.7t.275.7l1.9 1.9l-1.925 1.925Q8.4 14.2 8.4 14.6t.3.7ZM4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.588 1.413T20 20H4Zm0-2V6v12Zm0 0h16V6H4v12Z"
                      />
                    </svg>
                    Cancelled orders
                  </Card>
                </div>
                <div className="w-4/5 flex flex-col gap-2 h-fit p-2 md:p-8">
                  <div
                    className={`section ${
                      animationKey ? 'Line2 opacity-100' : 'opacity-0'
                    }`}>
                    <div className="bar5">
                      <span className="span5 text-xl font-bold text-black ">
                        114
                      </span>
                    </div>
                    <div className="bar6">
                      <span className="span6">24</span>
                    </div>
                    <div className="bar7">
                      <span className="span7">30</span>
                    </div>
                    <div className="bar8">
                      <span className="span8">60</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledBox>

        <div className="grid gap-4 md:gap-8 text-left grid-cols-1 md:grid-cols-2 mt-4 md:mt-8">
          <div className="col-span-1">
            <StyledBox
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 40
              }}>
              <h4 className="text-2xl font-semibold mb-2">
                Manage Chargebacks
              </h4>
              <h4
                className="text-xs font-normal mb-2"
                style={{ opacity: 0.5, flexGrow: 1 }}>
                Keep your chargebacks in check Don&apos;t! let your top line
                leak
              </h4>
              <Image
                src={chargebackImg}
                alt="chargebackimg"
                style={{ width: '100%', height: 'auto' }}
              />
            </StyledBox>
          </div>
          <div className="col-span-1">
            <StyledBox
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 40
              }}>
              <h3 className="text-2xl font-semibold mb-2">
                Keep your stores available to your customers
              </h3>
              <h3
                className="font-normal text-xs mb-2"
                style={{ opacity: 0.5, flexGrow: 1 }}>
                Maintain peak performance at all times
              </h3>
              <Image
                src={store_availabilityImg}
                alt="chargebackimg"
                style={{ width: '100%', height: 'auto' }}
              />
            </StyledBox>
          </div>
        </div>
      </div>
    </div>
  )
}
