'use client'
import cx from 'classnames'
import Image, { StaticImageData } from 'next/image'
import React from 'react'
import img36 from '../assets/webp/img36.webp'
import img37 from '../assets/webp/img37.webp'
import img38 from '../assets/webp/img38.webp'

import './style.scss'

const CustomBrandContainer = ({
  children,
  className
}: {
  children: React.ReactNode
  className: any
}) => (
  <div
    className={cx(
      className,
      'rounded-3xl flex relative bg-[#FAFAFA] w-full h-full border border-[#DBDBDB]'
    )}>
    {children}
  </div>
)

interface iLogoTrail {
  isShow: boolean
  src: string | StaticImageData
  label: string
  tag?: string
}

const logoTrail: iLogoTrail[] = [
  {
    isShow: true,
    // tag: 'Partnership',
    src: '/img/logos/olo.png',
    label: 'OLO'
  },
  {
    isShow: true,
    src: '/img/logos/uber_eats_new.webp',
    label: 'Uber Eats'
  },
  {
    isShow: true,
    src: '/img/logos/doordash_new.webp',
    label: 'Doordash'
  },
  {
    isShow: true,
    src: '/img/logos/grubhub_new.webp',
    label: 'Grubhub'
  },
  {
    isShow: true,
    src: '/img/logos/postmates.webp',
    label: 'Postmates'
  }
]

const StyledImg = ({
  src,
  alt,
  className
}: {
  alt: string
  src: StaticImageData | string
  className?: string
}) => (
  <Image
    src={src}
    width={80}
    alt={alt}
    height="80"
    className={cx(className, '  mr-2')}
  />
)
const StyledImage = ({
  src,
  alt,
  className,
  style
}: {
  alt: string
  src: StaticImageData
  className?: string
  style?: any
}) => (
  <Image
    src={src}
    style={{ height: 'auto', ...style }}
    className={`${className} w-9/10 md:w-1/2`}
    alt={alt}
  />
)

const StyledCard = ({
  img,
  title,
  children
}: {
  img: StaticImageData
  title: string
  children: React.ReactNode
}) => {
  const xs = false
  return (
    <div className="ze-card-invi-wrap w-full">
      <div
        className="p-4 ze-card pb-4 text-left flex flex-col justify-between md:ml-0 rounded-md styled_card h-auto h-max-[300px]"
        style={{
          paddingBottom: '1rem',
          maxWidth: !xs ? 500 : 'auto',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
        }}>
        <div className="mb-2 md:mb-0">
          {xs ? (
            <StyledImage
              alt="img"
              src={img}
              style={{
                maxHeight: 100,
                width: 'auto',
                height: 100
              }}
            />
          ) : (
            <StyledImage
              alt="img"
              src={img}
              className="mb-0 md:mb-3"
              style={{
                maxWidth: 80,
                maxHeight: 80,
                objectFit: 'contain',
                height: 80
              }}
            />
          )}
        </div>
        <h3 className="text-2xl font-normal mb-4 ze-title">{title}</h3>
        <h5
          className="text-sm font-normal ze-description"
          style={{ color: '#122937', opacity: 0.6, lineHeight: '25px' }}>
          {children}
        </h5>
      </div>
    </div>
  )
}

const Card: React.FC<{
  title: string
  description: string
  image: StaticImageData
  boldText?: string
}> = ({ title, description, image, boldText }) => {
  return (
    <div className="ze-card-invi-wrap flex-1 w-full">
      <div className="ze-card w-full">
        <div className="ze-img">
          <Image className="image" src={image} alt="img" />
        </div>
        <div className="ze-title">{title}</div>
        <div className="ze-description">
          {description}
          <span className="font-semibold">{boldText}</span>
        </div>
      </div>
    </div>
  )
}

export default function Section2() {
  React.useEffect(() => {
    const cards = document.querySelectorAll('.ze-card')

    cards.forEach((card) => {
      document.addEventListener('mousemove', (e) => {
        let ax = -(window.innerWidth - e.pageX) / 100
        let ay = (window.innerHeight - e.pageY) / 100
        card?.setAttribute(
          'style',
          'transform: rotateY(' +
            ax +
            'deg) rotateX(' +
            ay +
            'deg);-webkit-transform: rotateY(' +
            ax +
            'deg) rotateX(' +
            ay +
            'deg);-moz-transform: rotateY(' +
            ax +
            'deg) rotateX(' +
            ay +
            'deg)'
        )
      })
    })
  }, [])
  return (
    <div
      className="relative
      pt-5
      h-auto w-auto bg-white">
      <div className="container mx-auto relative w-4/5 md:w-4/5">
        <div
          className="ze-card-full-wrap py-10 flex-col md:flex-row gap-4 md:bg-[url('/chargebacks/lines.png')]"
          style={{
            backgroundPosition: '-23px -70px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '107% 135%'
          }}>
          <Card
            title="One Step Onboarding"
            description="Onboard right away with your 3rd party! Easy integration - no rip and replace"
            image={img36}
          />
          <Card
            title="Real time loss prevention"
            description="Alerts and notifications to prevent lost sales due to"
            image={img38}
            boldText=" 70+ reasons"
          />
          <Card
            title="Revenue Growth"
            description="Rank higher and drive more sales to your store "
            image={img37}
          />
        </div>
      </div>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 mx-auto justify-center w-5/6 relative my-8 md:my-20 bg-white auto-rows-fr">
        {logoTrail.map((item, index) => {
          return (
            <CustomBrandContainer
              className="col-span-1  relative"
              key={item.label}>
              {item.tag && (
                <div
                  className="top-2 left-2 text-xs rounded-2xl bg-white p-1.5 py-1 border border-neutral-200 absolute font-normal
                    ">
                  {item.tag}
                </div>
              )}

              <div
                className={cx('w-54 flex justify-start ', {
                  'top-7 relative md:absolute items-center w-22': item.tag,
                  'items-center': !item.tag
                })}>
                <StyledImg
                  src={item.src}
                  alt={item.label}
                  className={cx('p-3')}
                />

                <div className={cx('w-44 flex-1 font-semibold text-xl pr-10')}>
                  {item.label}
                </div>
              </div>
            </CustomBrandContainer>
          )
        })}
      </div>
      <div style={{ width: '100%', height: 50, marginTop: -20 }} />
    </div>
  )
}
