'use client'
import { useDialogData } from 'src/context/DialogContext'

export default function Btn() {
  const { openDialog } = useDialogData()
  return (
    <a
      className="bg-[#196E82] text-white flex justify-center items-center cursor-pointer w-full py-4 md:w-fit md:px-16 rounded"
      onClick={(e) => {
        e.preventDefault()
        openDialog(e)
      }}
      target="_blank">
      GET STARTED
    </a>
  )
}
