'use client'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import img41 from '../assets/webp/img41.webp'

const Bar = ({
  borderColor,
  background1,
  background2,
  style1,
  style2,
  children,
  className,
  h1,
  h2
}: {
  borderColor?: string
  background1?: string
  background2?: string
  style1?: any
  style2?: any
  children?: React.ReactNode
  className?: string
  h1?: string
  h2?: string
}) => {
  const [ref, inView] = useInView({
    threshold: 0.1
  })
  const [animationKey, setAnimationKey] = useState(false)
  const [s, setS] = useState(1)

  useEffect(() => {
    setAnimationKey(inView)
  }, [inView])
  return (
    <div className="h-full cols-span-1 flex flex-col justify-end py-8">
      <div className="flex flex-col justify-end items-start gap-1 mb-6">
        <div className="flex  items-center gap-2">
          <div className="h-2 w-10" style={{ background: background1 }}></div>
          <h3 className="text-[6px] md:text-[10px]">{h1}</h3>
        </div>
        <div className="flex  items-center gap-2">
          <div className="h-2 w-10" style={{ background: background2 }}></div>
          <h3 className="text-[6px] md:text-[10px]">{h2}</h3>
        </div>
      </div>
      <StyledBox
        className="mb-4 pt-12 flex flex-col gap-[2px] items-center justify-end"
        style={{ height: '75%' }}>
        <div
          ref={ref}
          className={`h-0  bg-black rounded-[10px] ${
            animationKey ? `animate2 ${className}` : ''
          }`}
          style={{ width: '30%', ...style1 }}></div>
        <div
          ref={ref}
          className={`h-0  bg-black rounded-[10px] ${
            animationKey ? 'animate1 ' : ''
          }`}
          style={{
            width: '30%',
            background: `linear-gradient(30deg,${background1},${background2})`
          }}></div>
      </StyledBox>
    </div>
  )
}

const StyledBox = ({
  borderColor,
  background,
  style,
  children,
  className
}: {
  borderColor?: string
  background?: string
  style: any
  children: React.ReactNode
  className?: string
}) => (
  <div
    className={`${className} p-4 md:p-6`}
    style={{
      borderRadius: 16,
      backgroundColor: background || 'white',
      border: `1px solid ${borderColor || '#D5D4D4'}`,
      ...style
    }}>
    {children}
  </div>
)

export default function Section4() {
  return (
    <div className="p-4 md:p-24 bg-white relative">
      <div className="container mx-auto">
        <h3 className="font-normal text-3xl md:text-6xl text-center mb-8 md:md-20">
          Maximize your marketing ROI
        </h3>
        {/* <div
          className="mb-6 p-4 md:p-8 rounded-md"
          style={{
            background: 'rgba(248, 248, 248, 0.3)',
            border: '1px solid #D3D3D3'
          }}>
          <h4 className="text-base text-center md:text-left md:text-2xl my-4 font-semibold">
            Supercharge your menu
          </h4>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div className="col-span-1">
              <div
                style={{
                  border: '1px solid #D5D4D4',
                  background: '#FFFFFF',
                  height: '100%'
                }}
                className="p-4 md:p-8 rounded-md">
                <h5 className="text-base font-semibold mb-6">
                  Experiment with your images
                </h5>
                <div
                  style={{
                    height: 'calc(100% - 50px)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                  }}>
                  <Image
                    src={img39}
                    alt="img39"
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <h3
                    className="text-base font-normal text-center p-1 my-1 rounded-md mx-auto"
                    style={{
                      width: 40,
                      maxWidth: 40,
                      maxHeight: 40,
                      background: 'rgba(218, 222, 223, 0.3)'
                    }}>
                    vs
                  </h3>
                  <Image
                    src={img40}
                    alt="img39"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div
                className="p-4 md:p-8 rounded-md flex flex-col"
                style={{
                  border: '1px solid #D5D4D4',
                  background: '#FFFFFF',
                  height: '100%'
                }}>
                <h3 className="text-base mb-6 font-semibold">
                  Tailor your menu to digital ordering
                </h3>
                <div
                  className="rounded-md"
                  style={{
                    border: '1px solid #DFDFDF',
                    height: '100%',
                    marginBottom: 16,
                    padding: 16,
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <div>
                    <div className="mb-2 flex justify-start items-center">
                      <Image
                        src={addCategoryImg}
                        alt="addCategory"
                        className="w-8 h-9 mr-4 rounded-sm border-2 border-gray-300"
                      />
                      <div>
                        <h3 className="text-base font-semibold">
                          Add category
                        </h3>
                        <h3
                          className="text-sm font-normal"
                          style={{ opacity: 0.5 }}>
                          Add new category to your menu
                        </h3>
                      </div>
                    </div>
                    <div className="mb-2 flex justify-start items-center">
                      <Image
                        src={addItemImg}
                        alt="addCategory"
                        className="w-8 h-9 mr-4 rounded-sm border-2 border-gray-300"
                      />
                      <div>
                        <h3 className="text-base font-semibold">Add item</h3>
                        <h3
                          className="text-sm font-normal"
                          style={{ opacity: 0.5 }}>
                          Add items to your category and menus
                        </h3>
                      </div>
                    </div>
                    <div className="mb-2 flex justify-start items-center">
                      <Image
                        src={addModifierImg}
                        alt="addCategory"
                        className="w-8 h-9 mr-4 rounded-sm border-2 border-gray-300"
                      />
                      <div>
                        <h3 className="text-base font-semibold">
                          Add modifier
                        </h3>
                        <h3
                          className="text-sm font-normal"
                          style={{ opacity: 0.5 }}>
                          Add items to your category and menus
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="flex items-center p-6 rounded-md mt-4"
                  style={{
                    border: '1px solid #DFDFDF',
                    height: '100%'
                  }}>
                  <div>
                    <div className="flex flex-wrap md:flex-nowrap justify-between mb-4">
                      <div className="text-xl font-semibold mb-4 md:mb-0 flex items-center">
                        Calzone
                      </div>
                      <div className="flex items-center justify-center text-cyan-900 text-xs font-semibold uppercase">
                        <div className="flex rounded-sm bg-slate-200 mr-2.5 p-2.5">
                          suggest a description
                        </div>
                        <div className="flex rounded-sm bg-slate-200 p-2.5">
                          suggest an image
                        </div>
                      </div>
                    </div>
                    <h3
                      className="font-normal text-sm"
                      style={{ lineHeight: '23px' }}>
                      A calzone is a type of Italian turnover made from folded
                      pizza dough and filled with various ingredients, such as
                      cheese, meat, vegetables, and seasonings. It is typically
                      baked in an oven until the crust is golden brown and the
                      filling is hot and melty.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="p-4 md:p-6 rounded-md"
          style={{
            background: 'rgba(248, 248, 248, 0.3)',
            border: '1px solid #D3D3D3'
          }}>
          {/* <h3 className="text-2xl font-semibold mb-6 mt-2">
            Maximize your marketing ROI
          </h3> */}
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div className="col-span-1">
              <StyledBox style={{ height: '100%' }} className="p-4 md:p-6">
                <h4 className="font-semibold text-base mb-4">
                  Automate campaign selection based on your goals
                </h4>
                <Image
                  alt="img"
                  src={img41}
                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
              </StyledBox>
            </div>
            <div className="col-span-1 h-[25rem] md:h-full">
              <StyledBox style={{ height: '100%' }}>
                <h4 className="font-semibold text-base mb-4">
                  Find your customers efficiently
                </h4>
                <div className={`grid grid-cols-3 h-full gap-4`}>
                  <Bar
                    h1="New Customer"
                    h2="Old Customer"
                    className="animate3"
                    background1="rgba(201, 218, 222, 1)"
                    background2="rgba(25, 110, 130, 1)"
                    style1={{
                      background: ' linear-gradient(0deg, #C9DADE, #C9DADE)',
                      border: '1px solid rgba(63, 133, 150, 1)'
                    }}
                  />
                  <Bar
                    h1="High LTV"
                    h2="One time visitors"
                    background1="rgba(255, 191, 164, 1)"
                    background2="rgba(241, 84, 18, 1)"
                    style1={{
                      background: 'linear-gradient(0deg, #FFBFA4, #FFBFA4)',
                      border: '1px solid rgba(244, 124, 73, 1)'
                    }}
                  />
                  <Bar
                    h1="Price sensitive"
                    h2="Quality oriented"
                    background1="rgba(249, 220, 177, 1)"
                    background2="rgba(255, 183, 74, 1)"
                    style1={{
                      background: 'linear-gradient(0deg, #F9DCB1, #F9DCB1)',
                      border: '1px solid rgba(255, 194, 103, 1)'
                    }}
                  />
                </div>
              </StyledBox>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mt-24">
        <p
          className="text-6xl mb-11"
          style={{ color: '#4B4C4D', lineHeight: '72px' }}
        >
          Testimonials
        </p>
        <Testimonial />
      </div> */}
    </div>
  )
}
