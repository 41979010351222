import adityaImg from 'src/assets/case_study/aditya.png'
import aliImg from 'src/assets/case_study/ali.png'
import davidImg from 'src/assets/case_study/david.png'
import financesImg from 'src/assets/case_study/finances.png'
import kateImg from 'src/assets/case_study/kate.png'
import macImg from 'src/assets/case_study/mac_2.png'
import mackbookImg from 'src/assets/case_study/mackbook.png'
import mixt_brands from 'src/assets/case_study/mixt_brands.png'
import popchew_brands from 'src/assets/case_study/popchew_brands.png'
import popchew_chargebacks from 'src/assets/case_study/popchew_chargeback.png'
import todc_brands from 'src/assets/case_study/todc_brands.png'

export const store_availability = {
  heading: 'Store Availability case study',
  title: 'How Loop Keeps The Lights on for Popchew',
  locations: '100',
  brands: '7',
  types: 'Virtual Brand',
  sales: '$7M/y',
  heroImg: macImg,
  brandsImg: popchew_brands,
  boxes: [
    {
      title: 'Avoided in lost sales',
      value: '$20k+',
      desc: 'per month'
    },
    {
      title: 'Saved',
      value: '16hrs',
      desc: 'per month'
    },
    {
      title: 'Uptime increase',
      value: '10%',
      desc: ''
    }
  ],
  testimonial: {
    img: adityaImg,
    name: 'Aditiya',
    designation: 'Founding team Popchew',
    words:
      "“We've been using Loop's Store Availability dashboard for some time now, and it has been a game-changer for our virtual brand. With real-time alerts and notifications, we were able to monitor and improve our stores' ability to accept orders by 30%. The solution is easy to use and has become an integral part of our workflows. Loop has helped us avoid lost sales and keep our customers happy.”"
  },
  features: {
    title: 'Store Availability features',
    types: [
      {
        title: 'Total Visibility',
        desc: 'Accurate store hours across multiple platforms',
        img: 'eye '
      }
    ]
  },
  bgcolor: '#D1FCE2'
}
export const shift_reports = {
  title:
    "Mixt's Winning Recipe: Chat GPT Streamlines Weekly Performance Reports and Enhances Service",
  locations: '170',
  brands: '2',
  types: 'Multi-Unit',
  bgcolor: '#E2EEF1',
  sales: '$400K/y',
  heroImg: mackbookImg,
  boxes: [
    {
      title: 'Saved',
      value: '8-12hrs',
      desc: 'daily'
    },
    {
      title: 'From 2 weeks cadences to:',
      value: 'Daily weekly performance reports',
      desc: 'with Loop'
    }
  ],
  testimonial: {
    img: kateImg,
    name: 'Kate',
    designation: 'Marketing manager MIXT',
    words:
      "“With Loop we're now able to access real-time insights from our customers, which has not only saved us countless hours but has also allowed us to deliver better service to our customers”"
  },
  features: {
    title: 'Store Availability features',
    types: [
      {
        title: 'Total Visibility',
        desc: 'Accurate store hours across multiple platforms',
        img: 'eye '
      }
    ]
  },
  brandsImg: mixt_brands
}
export const finances = {
  title: 'Why OnDemand Recommends Loop for Effective Financial Control',
  locations: '1200',
  brands: '1400',
  types: 'Virtual Brand',
  sales: '$24M/y',
  heroImg: financesImg,
  brandsImg: todc_brands,
  bgcolor: '#FFF1DB',
  boxes: [
    {
      title: '',
      value: '100hrs',
      desc: 'Saved for the accounting team weekly'
    },
    {
      title: '',
      value: '9+',
      desc: 'Different finance categories to have more control on the payouts'
    },
    {
      title: '',
      value: '30-7 days',
      desc: 'Reduced the time of payments'
    }
  ],
  testimonial: {
    img: aliImg,
    name: 'Ali',
    designation: 'COO The OnDemand Company',
    words:
      "“Thanks to Loop's Financial Intelligence, we were able to automate and speed up our owner payouts for all our physical locations. Before, it used to take almost a month to process them, but now we can do it within one week, which is a huge time-saver. We've also eliminated over 100 hours of accounting work that was previously required to collate, analyze and process the payouts and commissions each week. Thanks to Loop's system, we now have better control over payouts.”"
  },
  features: {
    title: 'Store Availability features',
    types: [
      {
        title: 'Total Visibility',
        desc: 'Accurate store hours across multiple platforms',
        img: 'eye '
      }
    ]
  }
}
export const chargebacks = {
  title: 'How Popchew 2Xed revenue recovery using Loop',
  locations: '50-250',
  brands: '10',
  types: 'Virtual Brand',
  sales: '$5-25MM/y',
  heroImg: popchew_chargebacks,
  brandsImg: todc_brands,
  bgcolor: 'rgba(29, 213, 255, 0.19)',
  boxes: [
    {
      title: 'Reduced the turnaround from',
      value: '2 weeks to 4 days',
      desc: 'per month'
    },
    {
      title: 'Saved',
      value: '40+hrs',
      desc: 'per week'
    },
    {
      title: 'Improved the Chargeback wins',
      value: '2x',
      desc: ''
    }
  ],
  testimonial: {
    img: davidImg,
    name: 'David',
    designation: 'Head of Customer Success - Popchew',
    words:
      "“Thanks to Loop's complaint dispute process, we've been able to achieve significant time and efficiency savings. Specifically, we've saved over 40 hours each week on the time-consuming tasks of raising, tracking, and uploading evidence for subjective disputes. Additionally, we've improved the velocity of our dispute process by 60% and increased our coverage by two times”"
  },
  features: {
    title: 'Store Availability features',
    types: [
      {
        title: 'Total Visibility',
        desc: 'Accurate store hours across multiple platforms',
        img: 'eye '
      }
    ]
  }
}

export const testimonials = [
  {
    words: `“Off-premise is a quarter of Dave’s Hot Chicken’s order volume and as it continues to grow, and we want to ensure we’re positioning ourselves to operate sustainably and profitability. Our franchisees love Loop since for solving such a critical pain point"`,
    name: 'Jim Bitticks',
    designation: `President & COO at Dave's Hot Chicken`,
    orgLogo: `/img/logos/chicken.svg`,
    img: '/img/testimonials/jim_bitticks_daves.png'
  },
  {
    words: `“Loop understands the nuances of working with franchise groups, helping our restaurants be successful while keeping everything seamless. We look forward to working with them to simplify the complex systems around 3rd party delivery.”`,
    name: `Sean Thompson`,
    designation: `VP of IT at Freddy’s`,
    orgLogo: `/img/logos/Freddys.png`,
    img: '/img/testimonials/sean_thompson_freddys.png'
  },
  {
    words: `“Craveworthy’s 11 brands across 185 locations thrive on 3rd party delivery and Loop helps us get it right. Just one example of Loop’s impact is a downtime reduction from 309 mins to 120 mins in just 2 months helping us save $1000s and keep our digital doors open. Our store managers love the platform for being able to identify and solve for operational issues in real time. Loop is a key pillar of our digital strategy as we grow into a $1B brand.”`,
    name: `Gregg Majewski`,
    designation: `Owner of Craveworthy brands`,
    orgLogo: `/img/logos/craveworthy.png`,
    img: '/img/testimonials/gregg_majewsky_carevesworthy.png'
  },
  {
    words: `“Loop brings accountability to 3P Delivery automating bookkeeping and minimizing order errors, saving us time and 50-100bps of delivery costs. Their store performance benchmarking helps us get real time objective insights into our 3PD performance helping us keep 3P delivery sustainable and profitable”`,
    name: `Aaron N.`,
    designation: `Founder and CEO - Starbird`,
    orgLogo: '/img/logos/starbird.png',
    img: '/img/testimonials/aaron_startbird.png'
  }
]

export const logos1 = [
  {
    isShow: true,
    imgName: 'starbird.png',
    alt: 'Start Bird'
  },

  {
    isShow: true,
    imgName: 'wendy.svg',
    alt: 'Wendy'
  },
  {
    isShow: true,
    imgName: 'olgas.svg',
    alt: 'Olgas'
  },
  {
    isShow: true,
    imgName: 'chicken.svg',
    alt: 'Chicken'
  },
  {
    isShow: true,
    imgName: 'Freddys.png',
    alt: "Freddy's"
  },
  {
    isShow: true,
    imgName: 'mobettahs.png',
    alt: 'Mobettahs'
  },
  {
    isShow: true,
    imgName: 'wienerschnitzel.svg',
    alt: 'Wienerschnitzel'
  },
  // {
  //   isShow: true,
  //   imgName: 'planb.png',
  //   alt: 'Plan B'
  // },
  // {
  //   isShow: true,
  //   imgName: 'hueymongoos.png',
  //   alt: 'Huey Mongoos'
  // },
  // {
  //   isShow: true,
  //   imgName: 'Marcos-Logo.png',
  //   alt: 'Marcos Logo'
  // },

  {
    isShow: true,
    imgName: 'on-demand.svg',
    alt: 'On Demand Company'
  },
  {
    isShow: true,
    imgName: 'mixt.svg',
    alt: 'mixt'
  },
  {
    isShow: true,
    imgName: 'halal.svg',
    alt: 'Halal Guys'
  },

  {
    isShow: true,
    imgName: 'popchew.svg',
    alt: 'Popchew'
  },
  {
    isShow: true,
    imgName: 'rally.svg',
    alt: 'Rally'
  },
  {
    isShow: true,
    imgName: 'sabor.svg',
    alt: 'Sabor'
  },

  {
    isShow: true,
    imgName: 'shootz.svg',
    alt: 'Shootz'
  },
  {
    isShow: true,
    imgName: 'layne.svg',
    alt: 'Layne'
  },
  {
    isShow: true,
    imgName: 'the-cumin-club.svg',
    alt: 'The Cumin Club'
  },
  {
    isShow: true,
    imgName: 'wood-ranch.svg',
    alt: 'Wood Ranch'
  },
  {
    isShow: true,
    imgName: 'rreal-tacos.svg',
    alt: 'RReal Tacos'
  },
  {
    isShow: true,
    imgName: 'bell.svg',
    alt: 'Bell'
  },
  {
    isShow: true,
    imgName: 'catapult.svg',
    alt: 'Catapult'
  }
  // {
  //   isShow: true,
  //   imgName: 'curry-up-now.png',
  //   alt: 'Curry Up Now'
  // }
]
